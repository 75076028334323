import React, {useEffect, useState} from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
// import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {firebase} from '../../utils/firebase';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/authenticating',
  // We will display Google and Facebook as auth providers.
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID]
};

const useStyles = makeStyles(theme => ({
  dialog: {
    padding: '40px 40px 30px',
    textAlign: 'center',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      padding: '30px 30px 20px',
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
return <Slide direction="up" ref={ref} {...props} />;
});

export default function LoginDialog(props) {
  const [styledAuth, setStyledAuth] = useState(false)

  useEffect(() => {
    setStyledAuth(
      firebase
      ? <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
      : undefined)
  }, []);

  const classes = useStyles();
  const {onClose, open} = props;

  const handleClose = () => {
    onClose();
  };

  return (<Dialog onClose={handleClose} aria-labelledby="login-dialog-title" TransitionComponent={Transition} open={open}>
    <div className={classes.dialog}>
      <Grid container="container" direction='column' justify="center" alignItems="center">
        <Typography style={{
          maxWidth: '250px',
          lineHeight: '1.2',
          fontWeight: '600',
          fontSize: '22px',
          paddingBottom: '5px'
        }}>Continue to QuickStyle</Typography>
      </Grid>
      {styledAuth}
    </div>
  </Dialog>);
}
