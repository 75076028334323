import React, {useState, useEffect} from "react"
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
// import Fade from '@material-ui/core/Fade';
import WebFont from 'webfontloader';
// import mockup from "../images/mockup.png"
// import coloursMockup from "../images/colours-mockup.png"
// import styleMockup from "../images/style-mockup.png"
// import typographyMockup from "../images/typography-mockup.png"
import Loader from '../components/WidgetComponents/Loader'
import LoginDialog from '../components/WidgetComponents/LoginDialog'
import SignupDialog from '../components/WidgetComponents/SignupDialog'
import {auth, firestore} from '../utils/firebase';

// import * as typeformEmbed from '@typeform/embed'
import {navigate, graphql} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo.js"
import Img from "gatsby-image"
// import { initAmplitude } from './utilities/amplitude';
// const amplitude = typeof window !== `undefined`
//   ? require("amplitude-js")
//   : null

const useStyles = makeStyles(theme => ({
  title: {
    fontFamily: 'Work Sans',
    fontSize: '60px',
    fontWeight: 'bolder',
    textAlign: 'center',
    lineHeight: 1,
    padding: '0px 10px 20px',
    color: '#262626',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      margin: '0px',
      paddingBottom: '10px',
      padding: '0px 10px 20px',
      fontSize: '12vw'
    }
  },
  ctaTitle: {
    fontFamily: 'Work Sans',
    fontSize: '60px',
    fontWeight: 'bolder',
    textAlign: 'center',
    lineHeight: 1,
    padding: '20px 10px 20px',
    color: '#262626',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      margin: '0px',
      padding: '0px 0px 20px',
      paddingBottom: '10px',
      fontSize: '12vw'
    }
  },
  subtitle: {
    fontFamily: 'Work Sans',
    fontWeight: 'normal',
    fontSize: '30px',
    paddingBottom: '30px',
    textAlign: 'center',
    lineHeight: 1.2,
    color: '#262626',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: '7.5vw',
      paddingBottom: '20px'
    }
  },
  secondaryTitle: {
    fontFamily: 'Work Sans',
    fontSize: '50px',
    fontWeight: 'bolder',
    textAlign: 'center',
    lineHeight: 1,
    padding: '15px 50px',
    color: '#262626',
    // backgroundColor: 'red',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      margin: '0px',
      padding: '45px 0px',
      paddingBottom: '10px',
      fontSize: '10.2vw'
    }
  },
  secondarySubtitle: {
    color: '#262626',
    fontFamily: 'Work Sans',
    fontWeight: 'normal',
    fontSize: '25px',
    padding: '0px 40px',
    textAlign: 'center',
    lineHeight: 1.2,
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      padding: '0px 0px',
      fontSize: '6.8vw',
      paddingBottom: '20px'
    }
  },
  textContainer: {
    backgroundColor: '',
    // height: '50vh',
    // margin: '0px 50px 50px 50px',
    width: '40vw',
    paddingRight: '10px',
    // margin: '50px 50px 100px 50px',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      width: '80vw',
      // paddingRight: '0px',
      margin: '0px',
      padding: '0px 0px 10vw'
    }
  },
  mockupContainer: {
    backgroundColor: '',
    width: '50vw',
    minHeight: '80vh',
    margin: '-3vw',
    marginTop: '0px',
    [
      theme
        .breakpoints
        .down('md')
    ]: {
      minHeight: '70vh'
    },
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      width: '60vw',
      margin: '0vw',
      marginTop: '50px',
      minHeight: '30vh',
      // margin: '50px 50px 0px 50px',
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      width: '80vw',
      margin: '0vw',
      padding: '0px'
    }
  },
  secondaryMockupContainer: {
    backgroundColor: '',
    width: '40vw',
    // margin: '-3vw',
    marginTop: '0px',
    padding: '40px',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      width: '60vw',
      margin: '0vw',
      marginTop: '50px',
      // margin: '50px 50px 0px 50px',
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      width: '80vw',
      margin: '0vw',
      padding: '0px'
    }
  },
  mockup: {
    backgroundColor: '',
    width: '50vw',
    margin: '0vw 0vw 0vw 0vw',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      width: '60vw',
      // margin: '50px 50px 0px 50px',
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      width: '80vw',
      // marginTop: '30px',
      margin: '0px 0px 0px 0px',
      padding: '0px'
    }
  },
  secondaryMockup: {
    // backgroundColor: '',
    // width: '40vw',
    // margin: '0vw 0vw 0vw 0vw',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      // padding: '50px',
      // width: '60vw',
      // paddingBottom: '25px'
      // margin: '50px 50px 0px 50px',
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      // width: '80vw',
      //  marginTop: '30px',
      // margin: '0px 0px 0px 0px',
    }
  },
  secondaryTextContainer: {
    width: '40vw',
    backgroundColor: '',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      width: '90vw'
    }
  },
  secondaryButton: {
    margin: '25px',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      margin: '5px'
    }
  },
  navBar: {
    padding: '22px 35px 0px 40px',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      padding: '10px 15px 0px 20px'
    }
  },
  detailBlock: {
    padding: '30px 0px',
    backgroundColor: ''
  },
  allDetailsContainer: {
    width: '90vw',
    padding: '40px 20px 10px',
    backgroundColor: '',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      padding: '20px 20px 20px 20px',
      width: '90vw'
    }
  },
  headingContainer: {
    backgroundColor: '',
    width: '90vw',
    paddingTop: '0px',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      paddingTop: '0px'
    }
  },
  logoFont: {
    fontFamily: 'Lobster',
    fontSize: '25px',
    color: '#242424',
    marginBottom: '4px',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: '23px',
      // marginBottom: '-0.5px'
    }
  }
}));

if (typeof window !== `undefined`) {
  WebFont.load({
    google: {
      families: ['Work Sans:lighter,normal,bold,bolder', 'Lobster:lighter,normal,bold,bolder']
    }
  });
}

// if (amplitude) {
//   amplitude
//     .getInstance()
//     .init("b4712d7cb2ceb55f943a3685fcb61374");
//   amplitude
//     .getInstance()
//     .logEvent('home page view');
// }

function Index({
  data
}, props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [loginDialogIsOpen, setLoginDialogIsOpen] = useState(false);
  const [signupDialogIsOpen, setSignupDialogIsOpen] = useState(false);
  const [userStatus, setUserStatus] = useState('loading');

  // const [mockupLoaded, setMockupLoaded] = useState(false)
  // const [mockupAnimatedIn, setMockupAnimatedIn] = useState(false)

  const openLoginDialog = () => {
    if (userStatus == 'member') {
      navigate('/my/dashboard')
    } else {
      setLoginDialogIsOpen(true);
    }
  };
  const closeLoginDialog = value => {
    setLoginDialogIsOpen(false);
  };

  const openSignupDialog = () => {
    if (userStatus == 'member') {
      navigate('/my/dashboard')
    } else {
      setSignupDialogIsOpen(true);
    }
  };
  const closeSignupDialog = value => {
    setSignupDialogIsOpen(false);
  };

  useEffect(() => {
    if (!isLoaded) {
      auth.onAuthStateChanged((user) => {
        if (user) {
          const db = firestore
          let docRef = db
            .collection("users")
            .doc(user.uid);
          docRef
            .get()
            .then(function(doc) {
              if (doc.exists) {
                if (doc.data()['betaAccess'] == true) {
                  // window
                  //   .analytics
                  //   .track("Signed In");
                  // navigate('/my/dashboard')
                  setUserStatus('member')
                } else {
                  setUserStatus('')
                  // doc.data() will be undefined in this case
                  // navigate('/waitlist')
                }
              }
            })
            .catch(function(error) {
              console.log("Error getting document:", error);
            });
          // console.log(user)
          // setActiveUser(user);
        } else {
          // localStorage.setItem("onSignIn", "true");
          // console.log('Nope')
          // navigate('/signin')
        }
      })
      // if (typeof window !== `undefined`) {
      //   setTimeout(event => setMockupAnimatedIn(true), 200)
      // }
      // amplitude
      //   .getInstance()
      //   .init("b4712d7cb2ceb55f943a3685fcb61374");
      // amplitude
      //   .getInstance()
      //   .logEvent('home page view');
      // console.log('Amplitude: home page view')
      setIsLoaded(true)
    }
  }, [isLoaded]);

  // function openForm() {
  //   openSigninDialog()
  // }

  // function openForm() {
  //   window
  //     .analytics
  //     .track("Clicked Homepage CTA Button", {
  //        userId: user.id,
  //        gender: 'male',
  //        age: 33,
  //     });
  //    amplitude
  //      .getInstance()
  //      .logEvent('homepage CTA button click');
  //    console.log('Amplitude: homepage CTA button click');
  //   typeformEmbed.makePopup('https://solg.typeform.com/to/S6qaIN', {
  //      mode: 'drawer_left',
  //     autoOpen: true,
  //      autoClose: 3,
  //     hideScrollbars: true,
  //     onSubmit: function() {
  //       console.log('Typeform successfully submitted')
  //     }
  //   })
  // }

  const classes = useStyles();

  // <MenuButton/>

  let NavBar = (<Grid className={classes.navBar} container="container" direction="row" justify="space-between" alignItems="center">
    <Grid item="item">
      <Grid container="container" direction="row" justify="center" alignItems="center">
        <Typography className={classes.logoFont}>QuickStyle</Typography>
      </Grid>
    </Grid>
    <Button onClick={openLoginDialog} color='secondary' size='large'>Sign In</Button>
  </Grid>)

  let page = (<Grid container="container" direction="column" justify="center" alignItems="center" style={{
      backgroundColor: '#f7faff',
      minHeight: '100vh',
      padding: '0px',
      margin: '0px'
    }}>
    <SEO title='Try QuickStyle'/> {NavBar}
    <Grid container="container" direction="column" justify="center" alignItems="center">
      <Grid className={classes.headingContainer} container="container" direction="row" justify="center" alignItems="center">
        <div className={classes.mockupContainer} style={{
            // width: 'calc(200px + 10vw)'
          }}>
          <Img className={classes.mockup} fluid={data.mockupImage.childImageSharp.fluid}/>
        </div>
        <Grow in="in">
          <Grid item="item">
            <Grid className={classes.textContainer} container="container" direction="column" justify="flex-start" alignItems="center">
              <Typography className={classes.title}>Make your brand stand out, fast</Typography>
              <Typography className={classes.subtitle}>Create an awesome brand style guide in seconds.</Typography>
              <Button onClick={openSignupDialog} variant='contained' color='secondary' size='large' style={{
                  fontSize: '18px'
                }}>Get Early Access</Button>
            </Grid>
          </Grid>
        </Grow>
      </Grid>
      <div>
        <Grid className={classes.allDetailsContainer} container="container" direction="column" justify="center" alignItems="center">
          <Grid className={classes.detailBlock} container="container" direction="row-reverse" justify="space-evenly" alignItems="center">
            <div className={classes.secondaryMockupContainer}>
              <Img className={classes.secondaryMockup} fluid={data.typographyImage.childImageSharp.fluid}/>
            </div>
            <Grid className={classes.secondaryTextContainer} container="container" direction="column" justify="center" alignItems="center">
              <Typography className={classes.secondaryTitle}>Start with your name {<span role="img" aria-label="rocket">✍️</span>}</Typography>
              <Typography className={classes.secondarySubtitle}>Choose from a selection of popular fonts or add your own.</Typography>
              <Button className={classes.secondaryButton} onClick={openSignupDialog} variant='outlined' color='secondary' size='large'>Try QuickStyle</Button>
            </Grid>
          </Grid>
          <Grid className={classes.detailBlock} container="container" direction="row" justify="space-evenly" alignItems="center">
            <div className={classes.secondaryMockupContainer}>
              <Img className={classes.secondaryMockup} id='mockup' alt='Product Mockup' fluid={data.colorImage.childImageSharp.fluid}/>
            </div>
            <Grid className={classes.secondaryTextContainer} container="container" direction="column" justify="center" alignItems="center" style={{
                // padding: '50px 0px 0px 50px'
              }}>
              <Typography className={classes.secondaryTitle}>Throw in some colour {<span role="img" aria-label="rocket">🎨</span>}</Typography>
              <Typography className={classes.secondarySubtitle}>Pick a palette and customize it to match your brand.</Typography>
              <Button className={classes.secondaryButton} onClick={openSignupDialog} variant='outlined' color='secondary' size='large'>Try QuickStyle</Button>
            </Grid>
          </Grid>
          <Grid className={classes.detailBlock} container="container" direction="row-reverse" justify="space-evenly" alignItems="center">
            <div className={classes.secondaryMockupContainer}>
              <Img className={classes.secondaryMockup} id='mockup' alt='Product Mockup' fluid={data.styleImage.childImageSharp.fluid}/>
            </div>
            <Grid className={classes.secondaryTextContainer} container="container" direction="column" justify="center" alignItems="center">
              <Typography className={classes.secondaryTitle}>Share your style guide {<span role="img" aria-label="rocket">👌</span>}</Typography>
              <Typography className={classes.secondarySubtitle}>Send your brand to designers, devs, or your best friend.</Typography>
              <Button className={classes.secondaryButton} onClick={openSignupDialog} variant='outlined' color='secondary' size='large'>Try QuickStyle</Button>
            </Grid>
          </Grid>
          <Grid container="container" direction="row-reverse" justify="space-evenly" alignItems="center" style={{
              padding: '30px 0px 10px 0px'
            }}>
            <Grid container="container" direction="column" justify="center" alignItems="center" style={{
                padding: '5vw 10px 8vw 10px'
              }}>
              <Typography className={classes.ctaTitle}>Join before the public launch</Typography>
              <Typography className={classes.subtitle}>Try QuickStyle early and help us choose the best features.</Typography>
              <Button onClick={openSignupDialog} variant='contained' color='secondary' size='large' style={{
                  // margin: '0px',
                  fontSize: '18px'
                }}>Get Early Access</Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container="container" direction="row" justify="center" alignItems="center">
          <Button color='inherit' size='large' onClick={event => window.open('https://solomongomez.com')} style={{
              // margin: '10px',
              margin: '15px 15px 30px',
              color: 'grey',
              // textTransform: 'none'
            }}>
            Made by Solomon Gomez</Button>
        </Grid>
      </div>
    </Grid>
    <LoginDialog open={loginDialogIsOpen} onClose={closeLoginDialog}/>
    <SignupDialog open={signupDialogIsOpen} onClose={closeSignupDialog}/>
  </Grid>)

  return (<Layout>
    {
      (isLoaded)
        ? page
        : <Loader/>
    }</Layout>)
}

export default Index

export const query = graphql `
  query {
    mockupImage: file(
      relativePath: { regex: "/mobile-mockup/" }
    ) {
      childImageSharp {
        fluid(
          quality: 80
        ) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    },
    typographyImage: file(
      relativePath: { regex: "/typography-mockup/" }
    ) {
      childImageSharp {
        fluid(
          quality: 80
        ) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    },
    colorImage: file(
      relativePath: { regex: "/colours-mockup/" }
    ) {
      childImageSharp {
        fluid(
          quality: 80
        ) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    },
    styleImage: file(
      relativePath: { regex: "/style-mockup/" }
    ) {
      childImageSharp {
        fluid(
          quality: 80
        ) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    },
  }
`

// Share your fonts and colour palette with designers, developers, marketers and everyone who's helping you build your brand.
